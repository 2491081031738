<template>
  <div class="header">
    <img :src="item.picture" alt="zdjęcie lekarza oglądającego zdjęcie rendgenowskie">
    <div class="header-text">
      <h1>{{ item.name }}</h1>
      

    </div>
  </div>
</template>

<script>


export default {
  props: {
    item:{
      type: Object,
      required: true 
    }
  }
}
</script>


<style scoped lang="scss">

@import './../main.scss';
.header {
  
  width: 100%;  
  overflow: hidden;
  position: relative;
  height: 50rem; 
  
}

.header img {
  width: 100%;
  
  
  
}

.header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  pointer-events: none;
}

.header-text{
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), rgba(194, 193, 193, 0.3);
  padding: 2rem;
  z-index: 1;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  max-width: 60rem;
  strong, li, p, h2{
    color: $light-color;
  }
  strong{
    font-size: 1.9rem;
    color: $main-color-dark;
  }
  li, p{
    font-size: 1.8rem;
    margin-bottom: 1rem;
    list-style-type: none;

  }


  .medx{
    font-size: 3.2rem;
    color: $main-color;
  }
  h2{
    font-size: 2rem;
    margin-bottom: 0rem
  }
  p{
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

}

</style>
