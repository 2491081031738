<template>
  <div class="space"></div>
  <h1>Badania</h1>
  <div class="tests-content">
    
    <ResearchCard 
    class="card"
      v-for="(research, index) in researches" 
      :key="index" 
      :category="'examins'"
      :item="research.item_name"
      :name="research.name"
    >
      <template #icon="{ isHovered }">
        <component 
          :is="research.icon" 
          :fillColor="isHovered ? '#333466' : '#078b8b'"  
        />
      </template>
    </ResearchCard>
  </div>
</template>

<script>
import ResearchCard from '@/components/ResearchCard.vue'

import SkeletonIcon from '@/assets/icons/SkeletonIcon.vue'
import SpineIcon from '@/assets/icons/SpineIcon.vue'
import KneeIcon from '@/assets/icons/KneeIcon.vue'
import ToothIcon from '@/assets/icons/ToothIcon.vue'
import SkullIcon from '@/assets/icons/SkullIcon.vue'
import UVBIcon from '@/assets/icons/UvbIcon.vue'

export default {
  components: {
    ResearchCard,
    SkeletonIcon,
    SpineIcon,
    KneeIcon,
    ToothIcon,
    SkullIcon,
    UVBIcon
  },
  setup() {
    return {
      hoverIndex: -1,
      researches: [
        { name: 'Rentgen ogólno- diagnostyczny', icon: 'SkeletonIcon', item_name: 'rtg'},
        { name: 'Zdjęcia pomiarowe', icon: 'SpineIcon' , item_name: 'zdjecia-pomiarowe'},
        { name: 'Badania stresowe stawów', icon: 'KneeIcon' , item_name: 'stawy'},
        { name: 'Rentgen stomatologiczny', icon: 'ToothIcon' , item_name: 'stomatologiczne'},
        { name: 'Tomografia CBCT 3D', icon: 'SkullIcon' , item_name: 'cbct'},
      ]
    }
  },
  methods:{
   
    

  }
}
</script>


<style scoped lang="scss">

@import './../main.scss';



.tests {
  width: 100%;  
  overflow: hidden;
  position: relative;
  
}

.tests img {
  width: 100%;
  clip-path: inset(0 0 13% 0); 
  transform: scale(1.3); 
  transform-origin: center 70%;
}

.tests-header{
  margin: 1rem;
}



.tests-content{
  margin-top: 1rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 20rem);
  justify-content: center;
  gap: 2rem;

}

h1{
  margin: 1rem;
  margin-top: 4rem;
  text-align: center;
}


</style>
