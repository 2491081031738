import { defineStore } from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => ({
    message: 'Hello from Pinia!'
  }),
  actions: {
    updateMessage(newMessage) {
      this.message = newMessage
    }
  }
})

export const useSectionStore = defineStore('section', {
  state: () => ({
    section: 'default'
  }),
  actions: {
    setSection(newSection) {
      this.section = newSection
    }
  }
})



export const useMedicalStore = defineStore('medicalStore', {
  state: () => ({
    categories: {
      examins: {
        rtg: {
          name: 'Rentgen ogólno- diagnostyczny',
          description: `
            <p class="item-view-description">
              RTG Ogólnodiagnostyczne Pomimo dużej różnorodności dostępnych technik obrazowania to właśnie zdjęcia rentgenowskie są badaniami z wyboru wybieranymi przez lekarzy i pacjentów, ze względu na ich dostępność, cenę i bezpieczeństwo. </p><p class="item-view-description">Na podstawie opisu badania wykonanego przez lekarza radiologa, lekarz pierwszego kontaktu jest w stanie postawić diagnozę, rozpocząć leczenie lub zalecić dalsze konsultacje. Badania ogólnodiagnostyczne wykonujemy metodą cyfrową uzyskując wysoką rozdzielczość, dzięki temu osiągamy dokładniejszy obraz niż przy zastosowaniu tradycyjnych zdjęć analogowych. </p><p class="item-view-description">Każde badanie cyfrowe możemy wydrukować na tradycyjnej kliszy zachowując wysoką jakość obrazu. Do niektórych badań należy się wcześniej przygotować (jama brzuszna, miednicy, kręgosłup lędźwiowy).
            </p>
          `,
          icon: 'SkeletonIcon',
          picture: require('@/assets/pictures/xray/01.jpg'),
        },
        stomatologiczne: {
          name: 'Rentgen stomatologiczny',
          description: `
            <p class="item-view-description">
              To grupa badań obrazujących zęby. Zdjęcia punktowe (wewnątrzustne), to badania dokładne, obrazujące do 3 zębów, są szybkie, tanie i łatwo dostępne.
            </p>
            <p class="item-view-description">
              Badania pantomograficzne (panoramiczne) to zdjęcia przeglądowe jamy ustnej, lubiana przez stomatologów metoda obrazowania zębów ósemek. Wraz z telerentgenogramem (zdj. cefalometryczne) zlecane przez ortodontów zwykle przed założeniem aparatu ortodontycznego.
            </p>
            <p class="item-view-description">
              Prawidłowo wykonany telerentgenogram pozwala na dokonanie specjalistycznych pomiarów.
            </p>
            <p class="item-view-description">
              Wykonujemy badania wszystkimi technikami.
            </p>
          `,
          icon: 'SkeletonIcon',
          picture: require('@/assets/pictures/xray/01.jpg'),
        },
        cbct: {
          name: 'Tomografia CBCT 3D',
          description: `
           <p class="item-view-description">
            To obecnie jedna z najszybszych i najdokładniejszych metod diagnozowania pacjenta, znajdująca swe zastosowanie w każdej dziedzinie z zakresu stomatologii, chirurgii szczękowej i laryngologii. Zastosowana technologia umożliwia znaczne skrócenie czasu badania i redukcję dawki promieniowania, tak istotnej dla pacjenta, przy jednoczesnym uzyskaniu obrazów najwyższej jakości.
          </p>
          <p class="item-view-description">
            Posiadany przez nas aparat wykonuje bardzo dokładne badania dzięki swojej konstrukcji:
          </p>
          <ul class="item-view-description">
            <li>Siedząca pozycja pacjenta zapewnia komfort</li>
            <li>Obrót aż 270° przyczynia się do uzyskania dokładnego obrazu</li>
          </ul>

          `,
          icon: 'SkeletonIcon',
          picture: require('@/assets/pictures/xray/01.jpg'),
        },
        
      },
      therapy: {
        uvbTherapy: {
          name: 'Terapia UVB',
          description: 'Opis badania bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla',
          icon: 'UVBIcon',
        },
      },
    },
  }),
  getters: {
    getCategory: (state) => (category) => state.categories[category],
    getItemByCategory: (state) => (category, item) => state.categories[category]?.[item],
  },
});
