<template>
  <nav class="navbar">
    <div class="container">
      <div @click="changeSection('hero')">
        <img class="logo" src="@/assets/logo-light.svg" alt="Logo" >
      </div>
      <div class="menu">
        <span  
          @click="changeSection('examines')"
          active-class="active"
        >
          Badania
        </span>
        <span 
          @click="changeSection('specialists')"
          active-class="active"
        >
          Specjaliści
        </span>
        <span 
          @click="changeSection('fototerapy')"
          active-class="active"
        >
          Fototerapia
        </span>
        <span 
          @click="changeSection('contact')"
          active-class="active"
        >
          Kontakt
        </span>
        <a href="https://www.emedx.pl/Account/LogOn">Wyniki online</a>
      </div>
    </div>
  </nav>
</template>

<script>
import { useRouter } from 'vue-router';
import { useSectionStore } from '@/store.js'

export default {
  setup() {
    const router = useRouter();
    const sectionStore = useSectionStore();

    const changeSection = async (newSection) => {
      if (router.currentRoute.value.path !== '/') {
        await router.push('/'); 
      }
      await sectionStore.setSection(newSection); 
      await sectionStore.setSection('default'); 
    };

    return {
      changeSection,
    };
  },
};
</script>


<style scoped lang="scss">
@import './main.scss';

.navbar {
  background-color: rgba(18, 24, 25, 0.95);
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
}

.container {
  display: flex;
  padding: 0 1rem 0 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: $page-width
}

.logo {
  height: 5rem;
  cursor: pointer;
}

.menu {
  display: flex;
  gap: 1rem;
}

span, a {
  cursor: pointer;
  font-size: 1.2rem;
  white-space: nowrap;
  align-self: center;
  text-decoration: none;
  position: relative;
  padding-bottom: 0.15rem;
  color: $light-color;
  font-weight: bold;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.2rem;
    background-color:  $main-color;
    transform: scaleX(0);
    transform-origin: bottom left;
    transition: transform 0.3s ease-out;
  }

  &:hover:after {
    transform: scaleX(1.1);
    transform-origin: bottom left;
  }

  &.router-link-active:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &.router-link-active:after,
  &:hover:after {
    animation: drawUnderline 0.5s ease-out forwards;
  }
}

@keyframes drawUnderline {
  0% {
    transform: scaleX(0);
    transform-origin: bottom left;
  }
  80% {
    transform: scaleX(1.1); 
    transform-origin: bottom left;
  }
  100% {
    transform: scaleX(1); 
    transform-origin: bottom left;
  }
}
</style>