<template>
  <div class="overlay" @click.self="closePopup">
    <div class="popup">
      <button class="close-btn" @click="closePopup">&times;</button>
      <div class="popup-content">
        <div>
          <img :src=this.picture alt="Placeholder image" class="popup-img">
          
        </div><div class="popup-description-container">
        <h2 class="popup-title">{{ name }}</h2>
        <div  v-html="description"></div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    picture: {
      type: String,
      required: false
    }
  },
  methods: {
    closePopup() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">
@import './../main.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  
}

.popup {
  background: $light-color;
  width: 90%;
  height: 90%;
  padding: 20px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}


.popup-content{
  position: absolute;
  top: 4rem;
  width: 90%;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  

}


.popup-img {
  height: 35rem;
  width: 35rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  margin-top: 1rem;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
}

.popup-title {
 
  color: $dark-color;
  font-size: 2rem;
  margin-bottom: 10px;
}
.popup-description-container{
  height: 35rem;
  width: 35rem;
  background-color: $gray-color;
  box-shadow: 0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin: 1rem;
  overflow-y: auto;
}
.popup-description {
  margin: 2rem;
  text-indent: 2rem;
  text-align: left;
  color: $dark-color;
  font-size: 1.2rem;
  

}



.close-btn {
  position: absolute;
  top: .7rem;
  right: .7rem;
  width: 2.5rem;
  height: 2.5rem;
  background-color: $main-color-dark;
  color: $light-color;
  border: none;
  border-radius: 50%;
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: $secendary-color;
}
</style>
