<template>
  <div 
    class="research-card" 
    v-bind="$attrs" 
    @mouseenter="isHovered = true" 
    @mouseleave="isHovered = false"
    @mouseup="goToItem()"
  >
    <div class="icon">
      <slot name="icon" :isHovered="isHovered"></slot>
    </div>
    <div class="content">
      <h3 :class="{ 'text-hover icon-svg': isHovered }">{{ name }}</h3>
    </div>
  </div>
  <Popup
      v-if="isPopupOpen"
      :name="popupName"
      :description="popupDescription"
      :picture="popupPicture"
      @close="closePopup"
    />
</template>

<script>
import Popup from './TestPopupComponent.vue';

export default {
  components: {
    Popup,
  },
  props: ['name', 'description', 'picture', 'item', 'category'],
  inheritAttrs: false,  
  data() {
    return {
      isHovered: false,
      isPopupOpen: false,
      popupName: '',
      popupDescription: '',
      popupPicture: '',
      
    }
  },
  methods: {
    goToItem(){
      this.$router.push({ name: 'item', params: { category: this.category, item: this.item } });
    
    },
    openPopup(name, description, picture) {
      this.popupName = name;
      this.popupDescription = description;
      this.popupPicture = picture;
      this.isPopupOpen = true;
    },
    closePopup() {
      this.isPopupOpen = false;
    }
  }
}
</script>
  
 <style scoped lang="scss">

  @import './../main.scss';
.test-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 10rem;
  height: auto;
  border-radius: 10px;
  text-align: center;
  }

  .icon {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 10rem;
  }

  .content{
    color: $main-color;
  }

  .research-card {
    cursor: pointer;
    padding: 1rem;
    padding-top: 3rem;
    background-color: rgba(244, 244, 242, 0.85);
    border-radius: 5px;
  }
  
  .tests-description {
    font-size: 1.4rem;
  }

  h3, p {
    margin-top: 2rem;
    text-align: center;
    color: $main-color-dark;
  }

  .text-hover {
    color: $secendary-color;
  }

</style>
