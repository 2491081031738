<template>
  <div 
    class="item-card" 
  >
    <slot></slot>
  </div>
 
</template>

<script>


export default {

  props: [],
  inheritAttrs: false,  
  data() {
    return {
    
    }
  },
  methods: {
   
  }
}
</script>
  
 <style scoped lang="scss">

  @import './../main.scss';



 
  .item-card {
   
    padding: 1rem;
  
    background-color: rgba(244, 244, 242, 0.85);
    
  }
  
  
  h3, p {
    margin-top: 2rem;
    text-align: center;
    color: $dark-color;
  }

 
</style>
