<template>
  <div class="home">
  <item-component-header :item="this.examins" />
  
  <div class="item-view">
      
      <div class="item-view-content">
        
        
        
        <div class="item-card" v-html="this.examins.description"></div>
        <item-card>
          <h3>Zapisz się na badanie</h3>
          <p>Zadzwoń pod numer: <span class="number">748 512 787</span></p>
          <p>Jak przygotować sie do badania?</p>
          
        </item-card>
      </div>
    </div>
  </div>
</template>

<script>

import { useSectionStore } from '@/store.js'
import { useMedicalStore } from '@/store.js';
import ItemComponentHeader from '@/components/ItemComponentHeader.vue'
import ItemCard from '@/components/ItemCard.vue'
export default {
  name: 'ItemView',
  components: {
    ItemComponentHeader,
    ItemCard
  },
  setup(props) {
    const medicalStore = useMedicalStore();
    const examins = medicalStore.getItemByCategory(props.category, props.item);
    return { examins };
  },
 
  props: {
    category: {
      type: String,
      required: true,
    },
    item: {
      type: String,
      required: true,
    },
  }, 


  computed: {
    section() {
      const sectionStore = useSectionStore()
      return sectionStore.section 
    }
  },
  watch: {
    async section(newValue) {
      await this.scrollTo(newValue)
    }
  },
  methods: {
  scrollTo(ref) {
    const behavior = this.isMobile ? 'auto' : 'smooth';
    
    if (ref === 'contact') {
      this.$emit('scroll-to-contact');
    }

    if (this.$refs[ref] && this.$refs[ref].$el) {
      this.$refs[ref].$el.scrollIntoView({
        behavior: behavior,
        block: 'start'
      });
    } else if (this.$refs[ref]) {
      this.$refs[ref].scrollIntoView({ behavior: behavior, block: 'start' });
    }
  }
}

  
}
</script>

<style lang="scss">
@import './../main.scss';
.item-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.item-view-content{
  
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
 



}



.item-card {
   
   padding: 1rem;
   background-color: rgba(244, 244, 242, 0.85);

   
   h3, p {
   margin-top: 2rem;
   text-align: center;
   color: $dark-color;
 }

.item-view-description{
  text-indent: 2rem;
  text-align: left;
  font-size: 1.4rem;
}
.number{
  color: $main-color-dark;
 }
 }
 
 
 






</style>